<template>
  <div class="modalBg" @click="$emit('close')">
    <div class="modalContainer">
      <div class="modalContainer-inner" @click.stop>
        <slot />
      </div>
      <i class="modalContainer-close material-icons" @click="$emit('close')">cancel</i>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['close']
}
</script>

<style lang="scss" scoped>
.modalBg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: rgba(0, 0, 0, 0.7);
}
.modalContainer {
  display: flex;
  position: relative;
  max-height: 90vh;
  min-width: 320px;
  padding: 7px;
  background: var(--bg-color);
  border-radius: var(--radius);
  font-weight: normal;
  color: var(--tx-color);
  &-inner {
    flex: 1;
    padding: 8px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  &-close {
    font-size: 32px;
    position: absolute;
    top: 3px;
    right: 3px;
    cursor: pointer;
  }
}
::v-deep {
  .modalTitle {
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: bold;
  }
  p {
    line-height: 1.7;
    &:not(:last-child) {
      margin-bottom: 1.2em;
    }
  }
  ul, ol {
    margin: 15px 10px 15px 25px;
    li {
      font-size: 13px;
      &:not(:first-child) {
        margin: 8px 0;
      }
      img {
        margin: 8px 0 0 1em;
      }
    }
  }
  ul li {
    list-style: disc;
  }
  ol li {
    list-style: decimal;
  }
}
</style>

<style lang="scss">
.modal-enter-active,
.modal-leave-active {
  transition: 0.2s;
  .modalContainer {
    transition: 0.2s;
  }
}
.modal-enter-active {
  .modalContainer {
    transition-timing-function: cubic-bezier(.02,.73,.49,1.24);
  }
}
.modal-leave-active {
  .modalContainer {
    transition-timing-function: ease-in;
  }
}
.modal-enter-from,
.modal-leave-to {
  &.modalBg {
    background: rgba(0, 0, 0, 0);
  }
  .modalContainer {
    transform: scale(0);
  }
}
.modal-enter-to,
.modal-leave-from {
  &.modalBg {
    background: rgba(0, 0, 0, 0.7);
  }
  .modalContainer {
    transform: scale(1);
  }
}
</style>
