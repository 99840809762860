<template>
  <div class="loading" :style="size ? { width: `${size}px`, height: `${size}px` } : null" />
</template>

<script>
export default {
  props: {
    size: null
  }
}
</script>

<style lang="scss" scoped>
.loading {
  margin: auto;
  width: 24px;
  height: 24px;
  border: 3px solid;
  border-color: var(--border-color);
  border-top-color: var(--tx-color);
  border-radius: 100%;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
